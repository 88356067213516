import * as React from "react"
import Layout from "../components/Layout";
import Hero from '../components/Hero';
import About from "../components/About";
import Advantages from "../components/Advantages";
import HowItWork from "../components/HowItWork";
import SignUp from "../components/SignUp";


// markup
const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Advantages />
      <HowItWork />
      <SignUp />
    </Layout>
  );
}

export default IndexPage;

import React from 'react';

const HowItWork = () => (
  <div className="container how-it-work" id="howitworks">
    <div className="row justify-content-center">
      <div className="col-10 col-md-8 col-lg-6">
        <h2 className="h1 text-center">How it works</h2>
        <div className="order-list">
          <div className="order-list-item flex-column flex-sm-row">
            <div className="order-list-num"><span>1</span></div>
            <div className="order-list-desc">
              <h5 className="mb-3">Register in the app</h5>
              <p>Register in the app in less than 3 minutes. Alternatively, you can contact our customer success team for assistance.</p>
            </div>
          </div>
          <div className="order-list-item flex-column flex-sm-row">
            <div className="order-list-num"><span>2</span></div>
            <div className="order-list-desc">
              <h5 className="mb-3">Find your suppliers and place an order</h5>
              <p>Once registered, you get access to the widest range of suppliers with their prices all in one place. Choose from a selection of high-quality products and place your order in just a few taps.</p>
            </div>
          </div>
          <div className="order-list-item flex-column flex-sm-row">
            <div className="order-list-num"><span>3</span></div>
            <div className="order-list-desc">
              <h5 className="mb-3">Supplier immediately receives your order and delivers it to you at scheduled time.</h5>
              <p>We pass your order to the supplier and make sure that you’ll receive it just on time.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default HowItWork;
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
// import Subscribe from "./Subscribe";

import AppStore from "../images/appstore.svg";
import GooglePlay from "../images/googleplay.svg";

// markup
const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1090, quality: 78) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className="hero">
      <div className="container">
        <div className="hero-image">
          <Img fluid={data.hero.childImageSharp.fluid} alt="" />
        </div>
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-12 col-md-8 col-lg-5 position-relative">
            <h1 className="h2 mb-3">
              Buy from your suppliers online. All in one place
            </h1>
            <p className="mb-5">
              Marketplace in UAE that connects restaurants, supermarkets and
              small businesses with their suppliers, simplifying ordering,
              payment and invoicing processes.
            </p>
            {/* <Subscribe /> */}

            <div className="badges">
              <a href="https://apps.apple.com/ae/app/supplyme-b2b-marketplace/id1581877336">
                <AppStore />
              </a>
              <a href="https://play.google.com/store/apps/details?id=ae.supplyme.portal&utm_source=homw&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <GooglePlay />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';
import Advantage from './Advantage';

const items = [
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1PC90aXRsZT4gPGcgaWQ9ItCh0LvQvtC5XzEiIGRhdGEtbmFtZT0i0KHQu9C+0LkgMSI+IDxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMjQiIHk9IjMuMzEiIHdpZHRoPSI1MiIgaGVpZ2h0PSI5MyIgcng9IjQuNTIiIHJ5PSI0LjUyIj48L3JlY3Q+IDxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMzAiIHk9IjE2LjMxIiB3aWR0aD0iNDAiIGhlaWdodD0iNjEiPjwvcmVjdD4gPGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNDYiIHkxPSIxMC4zMSIgeDI9IjU1IiB5Mj0iMTAuMzEiPjwvbGluZT4gPGNpcmNsZSBjbGFzcz0iY2xzLTEiIGN4PSI1MC41IiBjeT0iODYuODEiIHI9IjUuNSI+PC9jaXJjbGU+IDwvZz4gPC9zdmc+IA==',
    title: 'All suppliers in one place',
    description: 'We partnered with a wide variety of suppliers to expand your choice. You are not limited to the same supplier anymore. Choose any with the best terms for you.',
  },
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1INC60L7Qv9C40Y88L3RpdGxlPiA8ZyBpZD0i0KHQu9C+0LlfMSIgZGF0YS1uYW1lPSLQodC70L7QuSAxIj4gPHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNDgsNTkuMzFWNDYuNTlhNC42NSw0LjY1LDAsMCwxLDQuNzktNC41aDBhNC42NSw0LjY1LDAsMCwxLDQuNzksNC41VjU5LjMxIj48L3BhdGg+IDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTU3LjU4LDU5LjMxVjUxYTQuNjEsNC42MSwwLDAsMSw0LjcxLTQuNWgwQTQuNjEsNC42MSwwLDAsMSw2Nyw1MXY4LjM2Ij48L3BhdGg+IDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTY3LDU5LjMxVjUzLjk0YTQuNSw0LjUsMCwwLDEsNC41LTQuNWgwYTQuNSw0LjUsMCwwLDEsNC41LDQuNXY1LjIyYzAsNi4yNS0uNDcsMzAtNC41LDM1LjE1SDQxLjExUzI4LjY3LDczLDI0Ljc1LDYxLjExYy0yLjQ4LTcuNTcsNS41NS05Ljc1LDguNjctMy4zN0MzNS41NSw2Mi4xLDM5LDY4LjMzLDM5LDY4LjMzVjI4Ljg5YTQuNSw0LjUsMCwwLDEsNC41LTQuNWgwYTQuNSw0LjUsMCwwLDEsNC41LDQuNVY1OS4zMSI+PC9wYXRoPiA8bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI0MyIgeTE9IjE3LjMxIiB4Mj0iNDMiIHkyPSIxLjMxIj48L2xpbmU+IDxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjMyIiB5MT0iMjkuMzEiIHgyPSIxNiIgeTI9IjI5LjMxIj48L2xpbmU+IDxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjcwIiB5MT0iMjkuMzEiIHgyPSI1NCIgeTI9IjI5LjMxIj48L2xpbmU+IDxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjM0LjY1IiB5MT0iMTkuNjMiIHgyPSIyNi43NSIgeTI9IjExLjczIj48L2xpbmU+IDwvZz4gPC9zdmc+IA==',
    title: 'Selected quality of products',
    description: 'We carefully select suppliers available on our platform, so we know that you\'ll get products of the best quality. No compromise on quality.',
  },
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1INC60L7Qv9C40Y8gMjwvdGl0bGU+IDxnIGlkPSLQodC70L7QuV8xIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDEiPiA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iODMuNzggOTMuMzEgMjYuNzggOTMuMzEgMjYuNzggMTQuMzEgNjMuNzIgMTQuMzEgODMuNzggMzQuNSA4My43OCA5My4zMSI+PC9wb2x5Z29uPiA8cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjYzLjc4IDE0LjMxIDYzLjc4IDM0LjMxIDgzLjc4IDM0LjMxIj48L3BvbHlsaW5lPiA8cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjE2Ljc4IDg0LjMxIDE2Ljc4IDUuMzEgNjMuNzggNS4zMSI+PC9wb2x5bGluZT4gPC9nPiA8L3N2Zz4g',
    title: 'Track the status of your orders',
    description: 'We’ll send you a notification once your order is out for delivery. Also, you can check order status in the app.',
  },
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1INC60L7Qv9C40Y8gMzwvdGl0bGU+IDxnIGlkPSLQodC70L7QuV8xIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDEiPiA8Y2lyY2xlIGNsYXNzPSJjbHMtMSIgY3g9IjUwLjUiIGN5PSI1MC40OCIgcj0iNDQuNSI+PC9jaXJjbGU+IDxlbGxpcHNlIGNsYXNzPSJjbHMtMSIgY3g9IjUwIiBjeT0iNTAuNDgiIHJ4PSIxMSIgcnk9IjQ0LjUiPjwvZWxsaXBzZT4gPGVsbGlwc2UgY2xhc3M9ImNscy0xIiBjeD0iNTAiIGN5PSI1MC40OCIgcng9IjI5IiByeT0iNDQuNSI+PC9lbGxpcHNlPiA8bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI2IiB5MT0iNDkuOTgiIHgyPSI5NSIgeTI9IjQ5Ljk4Ij48L2xpbmU+IDwvZz4gPC9zdmc+IA==',
    title: 'Use the power of AI and automate most of your procurement.',
    description: 'We use machine learning to understand when you need to re-stock. You need only to check the quantity and approve it.',
  },
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1INC60L7Qv9C40Y8gNDwvdGl0bGU+IDxnIGlkPSLQodC70L7QuV8xIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDEiPiA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iODIgOTIuOTcgMTggOTIuOTcgMTggNC45NyA1OS40OCA0Ljk3IDgyIDI3LjQ3IDgyIDkyLjk3Ij48L3BvbHlnb24+IDxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iNTkgNC45NyA1OSAyNy45OCA4MiAyNy45OCI+PC9wb2x5bGluZT4gPHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyOS44NCA3My4wNCA0NS42NCA1Ny4yNCA1My40NiA2NS4wNiA2OS42NSA0OC44NyI+PC9wb2x5bGluZT4gPHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI3MCA2MS45OCA3MCA0OC45OCA1NyA0OC45OCI+PC9wb2x5bGluZT4gPC9nPiA8L3N2Zz4g',
    title: 'Monthly reports of your procurement',
    description: 'Keep tracking of your procurement. Know exactly what you spend money on with just a few clicks. Compare your spending month-to-month or year-to-year.',
  },
  {
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gPGRlZnM+IDxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDA1NmQzO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoycHg7fTwvc3R5bGU+IDwvZGVmcz4gPHRpdGxlPnRlbXBsYXRlX2lsbHVzdHJhdGlvbtCc0L7QvdGC0LDQttC90LDRjyDQvtCx0LvQsNGB0YLRjCA1INC60L7Qv9C40Y8gNTwvdGl0bGU+IDxnIGlkPSLQodC70L7QuV8xIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDEiPiA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik05My43OCw1OFY0Mkg4My44NWEzNS4xMSwzNS4xMSwwLDAsMC00LjIxLTEwLjE2bDctN0w3NSwxMy4wOWwtNyw3YTM1LjExLDM1LjExLDAsMCwwLTEwLjE2LTQuMjFWNmgtMTZ2OS45M2EzNS4xMSwzNS4xMSwwLDAsMC0xMC4xNiw0LjIxbC03LTdMMTIuOSwyNC43OWw3LDdBMzUuMTEsMzUuMTEsMCwwLDAsMTUuNzEsNDJINS43OFY1OGg5LjkzYTM1LjExLDM1LjExLDAsMCwwLDQuMjEsMTAuMTZsLTcsNywxMS43LDExLjcsNy03QTM1LjExLDM1LjExLDAsMCwwLDQxLjc4LDg0Vjk0aDE2Vjg0YTM1LjExLDM1LjExLDAsMCwwLDEwLjE2LTQuMjFsNyw3LDExLjctMTEuNy03LTdBMzUuMTEsMzUuMTEsMCwwLDAsODMuODUsNThaIj48L3BhdGg+IDxjaXJjbGUgY2xhc3M9ImNscy0xIiBjeD0iNTAuMjgiIGN5PSI0OS40OCIgcj0iMTcuNSI+PC9jaXJjbGU+IDwvZz4gPC9zdmc+IA==',
    title: 'Invoices will never lost',
    description: 'Download your invoices directly from the app. We\'ll send you all your invoices at the end of each month, so you can easily pass them to your accountant.',
  },
];

const Advantages = () => (
  <div className="advantages">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-10">
          <h2 className="h1 text-center mb-3 mb-md-5">Even chef can restock</h2>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-md-5 align-items-stretch pt-4">
        {items.map((item) => (
          <div key={item.title} className="col mb-2">
            <Advantage {...item} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Advantages;
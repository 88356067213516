import React from 'react';

const Advantage = ({ icon, title, description }) => (
  <div className="adv">
    <div className="adv-icon">
      <img src={icon} width="60" height="60" alt={title} />
    </div>
    <h5 className="mb-3">{title}</h5>
    <p>{description}</p>
  </div>
);

export default Advantage;
import React from "react";

import AppStore from "../images/appstore.svg";
import GooglePlay from "../images/googleplay.svg";

const SignUp = () => (
  <div className="signup text-center" id="signup">
    <div className="container">
      <div className="signup-content">
        <h2>Download the app and register</h2>
        <p className="pb-4">
          Get access to a wide variety of suppliers over the whole UAE.
        </p>

        <div className="badges badges_footer">
          <a href="https://apps.apple.com/ae/app/supplyme-b2b-marketplace/id1581877336">
            <AppStore />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ae.supplyme.portal&utm_source=homw&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <GooglePlay />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default SignUp;

import React from 'react';
import Img from 'gatsby-image';

const Figure = ({ img, title, description, dir }) => (
  <div className={`row gx-5 about-fig align-items-center flex-column flex-md-row${dir ? '-reverse' : ''}`}>
    <div className="col-12 col-md-6 pb-3 pb-md-0">
      <Img 
        fluid={img.fluid} 
        alt={title}
        fadeIn={true}
        objectFit="cover"
        objectPosition="50% 50%"
      />
    </div>
    <div className="col-12 col-md-6">
      <div className="about-fig-content px-3 px-sm-0">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

export default Figure;
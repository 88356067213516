import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Figure from './Figure';

const About = () => {
  const data = useStaticQuery(graphql`
    query  {
      img1: file(relativePath: { eq: "colorful-grapefruit-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 78) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img2: file(relativePath: { eq: "sugar-castle-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 78) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img3: file(relativePath: { eq: "oranges-with-ice-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 78) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const items = [
    {
      title: 'Special prices from suppliers',
      description: 'Enjoy special discounted prices from suppliers by purchasing through our app. We already did all negotiations for you.',
      img: data?.img1?.childImageSharp,
    },
    {
      title: 'Restock in a few clicks',
      description: 'We learn from the way you purchase. We prepare your next order for your confirmation just before you need it.',
      img: data?.img2?.childImageSharp,
      dir: 'reverse',
    },
    {
      title: 'Payment in one tap',
      description: 'Attach your payment card once and settle all your payments directly from your bank account. No hassle. No extra fees.',
      img: data?.img3?.childImageSharp,
    },
  ];

  return (
    <div className="container about" id="about">
      {items.map((props) => <Figure key={props.title} {...props} />)}
    </div>
  );
};

export default About;
